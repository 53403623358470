/**
 * 获取本地Token
 */
export const getToken = async tokenkey => {
  return window.localStorage.getItem(tokenkey)
}

/**
 * 设置存储本地Token
 */
export const setToken = async (tokenkey, token) => {
  return window.localStorage.setItem(tokenkey, token)
}

/**
 * 移除本地Token
 */
export const removeToken = async tokenkey => {
  return window.localStorage.removeItem(tokenkey)
}

export const clearLocalStorage = name => {
  window.localStorage.removeItem(name)
}

export const getTokenObject = accessToken => {
  let jsonObj = {}
  if (accessToken) {
    // const str = accessToken.substring(accessToken.indexOf('.')+1);
    // const base64Str = str.substring(0,str.indexOf('.'));
    // 解码base64
    const jsonStr = Buffer.from(accessToken, 'base64').toString()
    // 下面这个方法也可以
    // const jsonStr = decodeURIComponent(escape(window.atob( accessToken )));
    // 转换成JSON对象
    jsonObj = JSON.parse(jsonStr)
  }
  return jsonObj
}

export const setTokenObject = obj => {
  let base64Str = ''
  if (obj) {
    if (getPropyte(obj) === 'string') {
      base64Str = window.btoa(unescape(encodeURIComponent(obj)))
    }
    if (getPropyte(obj) === 'object') {
      let newObj = JSON.stringify(obj)
      base64Str = window.btoa(unescape(encodeURIComponent(newObj)))
    }
  }
  return base64Str
}
