import Vue from 'vue'
import VueRouter from 'vue-router'
import cache from './cache'
import dashboard from './dashboard'
import { setToken, getTokenObject, setTokenObject } from '@/@core/utils/localToken'
import { getToken, clearLocalStorage, removeToken } from '@/@core/utils/localToken'
import jwt from 'jsonwebtoken'
import SecurityLayout from '@/layouts/variants/content/SecurityLayout.vue'
Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.

  {
    path: '/',
    // component: () => import('@/layouts/variants/content/SecurityLayout.vue'),
    redirect: to => {
      const userData = 'admin'
      const userRole = userData && userData.role ? userData.role : null

      //   console.log(getTokenObject(setTokenObject()), 'sadasd')

      if (window.localStorage.getItem('token')) {
        //解析token 拿到用户信息
        const decoded = jwt.decode(window.localStorage.getItem('token'))
        setToken('userInfo', JSON.stringify(decoded))

        if (userRole === 'admin') return { name: 'dashboard' }
        if (userRole === 'client') return { name: 'page-access-control' }
      } else {
        const authBasicUrl = 'http://10.230.168.152:31511/auth'
        const hash = window.location.hash || '/'
        let newUrl = ''
        newUrl = authBasicUrl + '/#/user/login?code=logout&redirect=' + window.location.origin + '/' + hash
        window.open(newUrl, '_self')
      }
      return { name: 'auth-login' }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  ...dashboard,
  ...cache,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, form, next) => {
  console.log('我出发了', to)
  //   console.log('authToken2', to['query'], to['query']['auth-token'])
  if (to['query'] && to['query']['auth-token']) {
    setToken('token', to['query']['auth-token'])
    next({ path: to['path'], replace: true })
  } else {
    next()
  }
})

export default router
