import {
    mdiChartAreaspline,
} from '@mdi/js'

export default [
    {
        title: 'Dashboard',
        icon: mdiChartAreaspline,
        to: { name: 'dashboard-default', params: { id: 21 } },
    },
]
