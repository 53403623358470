<template>
    <div>
        <component :is='resolveLayoutVariant' :class='`skin-variant--${appSkinVariant}`'>
            <transition :name='appRouteTransition' mode='out-in' appear>
                <router-view></router-view>
            </transition>
        </component>
        <Snackbar />
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'

// Layouts
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'
import Snackbar from '@core/components/Snackbar.vue'

export default {
    components: {
        LayoutContentVerticalNav,
        LayoutContentHorizontalNav,
        LayoutBlank,
        Snackbar,
    },
    setup() {
        const { route } = useRouter()
        const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

        const { handleBreakpointLayoutSwitch } = useLayout()
        handleBreakpointLayoutSwitch()

        const resolveLayoutVariant = computed(() => {
            if (route.value.meta.layout === 'blank') return 'layout-blank'
            if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

            return null
        })

        useDynamicVh()

        // 全局加载
        // TODO 查看token是否有效，无效则跳转通用登录界面

        return {
            resolveLayoutVariant,
            appSkinVariant,
            appRouteTransition,
        }
    },
}
</script>
