const cache = [

    // 文件

    // 瓦片
    {
        path: '/apps/cache/tile/list',
        name: 'apps-cache-tile-list',
        component: () => import('@/views/apps/cache/tile/list/CacheTileList.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/apps/cache/tile/token',
        name: 'apps-cache-tile-token',
        component: () => import('@/views/apps/cache/tile/list/CacheTileList.vue'),
        meta: {
            layout: 'content',
        },
    },

]

export default cache
