<template>
    <div>
123123
    </div>
</template>

<script>
    export default {
        mounted() {
            console.log('asdasd')
        },
        methods: {

        }
    }
</script>

<style lang="scss" scoped>

</style>