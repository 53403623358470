import {
    mdiImageText,
    mdiFormatListChecks,
} from '@mdi/js'

export default [
    {
        subheader: 'cache',
    },
    {
        title: 'Tile Cache',
        icon: mdiImageText,
        children: [
            {
                title: 'List',
                icon: mdiFormatListChecks,
                to: { name: 'apps-cache-tile-list', params: { id: 21 } },
            },
        ],
    },
    // {
    //     title: 'File Cache',
    //     icon: mdiAccountOutline,
    //     children: [
    //         {
    //             title: 'List',
    //             to: { name: 'apps-users-driver-list', params: { id: 21 } },
    //         },
    //         {
    //             title: 'Preview',
    //             to: { name: 'apps-users-vehicle-list', params: { id: 21 } },
    //         },
    //     ],
    // },
]
