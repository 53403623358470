import { mdiChartBar } from '@mdi/js'

const dashboard = [

    // 文件

    // 瓦片
    {
        path: '/dashboard',
        name: 'dashboard-default',
        icon: mdiChartBar,
        component: () => import('@/views/apps/dashboard/Dashboard.vue'),
        meta: {
            layout: 'content',
        },
    },

]

export default dashboard
